import "@styles/common.css";
import "@styles/font.css";
import "@styles/typography.css";
import "@styles/base.css";
import "@styles/layout.css";
import "@styles/colors.css";
import "@styles/transitions.css";
import "@styles/icons.css";
import "@styles/locomotive-scroll.css";

import Napulitano from "@blocks/Napulitano";
import About from "@blocks/About";

import Columns from "@components/Columns";
import TextMaskBackground from "@components/TextMaskBackground";
import AnimatedText from "@components/AnimatedText";

import Raf from "@utils/Raf";
import Observer from "@utils/Observer";
import Scroll from "@utils/Scroll";
import vh from "@utils/vh";

const MODULES = [Napulitano, About, Columns, TextMaskBackground, AnimatedText];

class App {
  lastIndex = 0;

  constructor() {
    window.addEventListener("DOMContentLoaded", this.prepareToInitApp);
    window.addEventListener("load", this.loadApp);
    window.addEventListener("resize", this.onResize);
    window.addEventListener("scroll", this.onScroll);
  }

  prepareToInitApp = () => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.register("/sw.js").then(
        function (registration) {
          // Registration was successful
          console.log(
            "ServiceWorker registration successful with scope: ",
            registration.scope
          );
        },
        function (err) {
          // registration failed :(
          console.log("ServiceWorker registration failed: ", err);
        }
      );
    }

    this.raf = new Raf();
    this.observer = new Observer();

    this.initApp();
  };

  initApp = () => {
    document.documentElement.style.setProperty("--dvh", `${vh()}px`);
    this.lastIndex = 0;

    this.instances = MODULES.flatMap((Module) => {
      if ("selector" in Module) {
        const blocks = [...document.querySelectorAll(Module.selector)];

        return blocks.map((block) => {
          block.setAttribute("data-instance-index", this.lastIndex);
          this.lastIndex += 1;
          return new Module(block);
        });
      }
      return null;
    }).filter(Boolean);

    this.scroll = new Scroll();

    for (const instance of this.instances) {
      if (instance.onReady) {
        instance.onReady();
      }
    }

    setTimeout(() => {
      for (const instance of this.instances) {
        if (instance.onComplete) {
          instance.onComplete();
        }
      }

      this.scroll.update();
    }, 200);
  };

  initClones = () => {
    this.instances = this.instances.concat(
      MODULES.flatMap((module) => {
        if (!module.hasOwnProperty("selector")) {
          return;
        }

        const blocks = [...document.querySelectorAll(module.selector)];
        return blocks
          .map((block) => {
            const blockInstanceIndex = block.getAttribute(
              "data-instance-index"
            );
            if (blockInstanceIndex && blockInstanceIndex !== "") {
              return null;
            }

            block.setAttribute("data-instance-index", this.lastIndex);
            this.lastIndex++;
            return new module(block);
          })
          .filter((v) => !!v);
      })
    );

    this.instances.forEach((instance) => {
      if (instance.onReady && !instance.mounted) {
        instance.onReady();
      }
    });
  };

  loadApp = () => {
    document.body.classList.add("loaded");
  };

  onResize = (e) => {
    if (!this.instances) {
      return;
    }

    document.documentElement.style.setProperty("--dvh", `${vh()}px`);

    for (const instance of this.instances) {
      if (instance.onResize) {
        // instance.onResize(e, { changedView });
        instance.onResize(e);
      }
    }
  };

  onScroll = (e) => {
    if (!this.instances) {
      return;
    }

    for (const instance of this.instances) {
      if (instance.onScroll) {
        instance.onScroll(e);
      }
    }
  };
}

window.App = new App();

export const getRaf = () => {
  return window.App.raf;
};

export const getObserver = () => {
  return window.App.observer;
};

export const getInstance = (el) => {
  const instanceIndex = el.getAttribute("data-instance-index");

  if (!instanceIndex) {
    return null;
  }

  return window.App.instances[parseInt(instanceIndex, 10)];
};

export const getScroll = () => {
  return window.App.scroll;
};
