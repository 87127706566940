import "./style.css";
import layout from "./layout.html";
import dictionaries from "@assets/dictionaries";
import LetterPicker from "@blocks/LetterPicker";
import Dictionary from "@blocks/Dictionary";
import DictionaryPicker from "@blocks/DictionaryPicker";
import Nav from "@blocks/Nav";

export default class Napulitano {
  static selector = ".napulitano";

  constructor(block) {
    this.block = block;
    this.block.innerHTML = layout;
    this.dictionaryKeys = Object.keys(dictionaries);
    this.dictionaryKeyIndex = 0;
    this.dictionaryKey = this.dictionaryKeys[this.dictionaryKeyIndex];
    this.selectedDictionary = dictionaries[this.dictionaryKey];
    this.letter = Object.keys(this.selectedDictionary)[0];

    this.navNode = this.block.querySelector("nav");
    this.nav = new Nav(this.navNode);

    this.letterPickerNode = this.block.querySelector(".letter-picker");
    this.letterPicker = new LetterPicker(
      this.letterPickerNode,
      Object.keys(this.selectedDictionary)
    );
    this.letterPicker.registerOnOpen(this.onPickerOpen);
    this.letterPicker.registerOnClose(this.onPickerClose);
    this.letterPicker.registerOnChangeIndex(this.onIndexChanged);

    this.dictionaryNode = this.block.querySelector(".dictionary");
    this.dictionary = new Dictionary(
      this.dictionaryNode,
      this.selectedDictionary,
      this.dictionaryKey
    );
    this.dictionary.registerOnLetterEnter(this.onLetterEntered);

    this.dictionaryPickerNode = this.block.querySelector(".dictionary-picker");
    this.dictionaryPicker = new DictionaryPicker(this.dictionaryPickerNode);
    this.dictionaryPicker.registerOnClick(this.onDictionaryPickerChanged);
  }

  onDictionaryPickerChanged = () => {
    this.dictionaryKeyIndex = (this.dictionaryKeyIndex + 1) % 2;
    this.dictionaryKey = this.dictionaryKeys[this.dictionaryKeyIndex];
    this.selectedDictionary = dictionaries[this.dictionaryKey];
    this.letterPicker.updateLetters(Object.keys(this.selectedDictionary));
    this.dictionary.updateDictionary(
      this.selectedDictionary,
      this.dictionaryKey
    );
  };

  onIndexChanged = (index) => {
    this.dictionary.scrollTo(index);
  };

  onPickerClose = () => {
    this.dictionary.pickerStateChanged(0);
  };

  onPickerOpen = () => {
    this.dictionary.pickerStateChanged(1);
  };

  onLetterEntered = (letter) => {
    if (this.letter !== letter) {
      this.letter = letter;
      this.letterPicker.updateLetter(letter);
    }
  };

  onReady = () => {
    this.mounted = true;
    this.letterPicker.onReady();
    this.dictionary.onReady();
  };

  onResize = () => {
    this.letterPicker.onResize();
  };
}
