import "./style.css";
import layout from "./layout.html";
import { getScroll, getRaf } from "@app";

export default class Dictionary {
  static selector = ".dictionary";

  constructor(block, dictionary, key) {
    this.block = block;
    this.block.innerHTML = layout;
    this.lettersNode = block.querySelector(".dictionary__letters");
    this.letterTemplate = block.querySelector("#dictionary__letters__letter");
    this.wordTemplate = block.querySelector(
      "#dictionary__letters__letter__words__word"
    );

    this.dictionary = dictionary;
    this.letters = Object.keys(this.dictionary);

    this.keys = {
      it_na: ["italian", "neapolitan"],
      na_it: ["neapolitan", "italian"],
    };
    this.key = key;

    this.mounted = true;
    this.letterEnterCallbacks = [];
    this.animations = {
      pickerStateChanged: {
        node: this.lettersNode,
        styles: {
          blur: {
            dampingFactor: 0.09,
            toValue: 0,
            fromValue: 0,
            current: 0,
            setValue: () => {
              this.animations.pickerStateChanged.styles.blur.fromValue =
                this.animations.pickerStateChanged.styles.blur.fromValue +
                (this.animations.pickerStateChanged.styles.blur.toValue -
                  this.animations.pickerStateChanged.styles.blur.fromValue) *
                  this.animations.pickerStateChanged.styles.blur.dampingFactor;

              return this.animations.pickerStateChanged.styles.blur.fromValue;
            },
          },
        },
      },
    };
  }

  pickerStateChanged = (value) => {
    this.animations.pickerStateChanged.styles.blur.toValue = value;
  };

  registerOnLetterEnter = (callback) => {
    this.letterEnterCallbacks.push(callback);
  };

  setupWord = (translation, index) => {
    let wordNode = this.wordTemplate.content.firstElementChild.cloneNode(true);

    if (wordNode) {
      const wordOriginal = wordNode.querySelector(
        ".dictionary__letters__letter__words__word__original"
      );
      wordOriginal.textContent = `${translation[this.keys[this.key][0]]}: `;

      const wordTranslation = wordNode.querySelector(
        ".dictionary__letters__letter__words__word__translation"
      );
      wordTranslation.textContent = translation[this.keys[this.key][1]];

      wordNode.classList.toggle(
        "dictionary__letters__letter__words__word--ghost",
        index >= 5
      );

      return wordNode;
    } else {
      return null;
    }
  };

  setupLetter = (letter) => {
    let letterNode =
      this.letterTemplate.content.firstElementChild.cloneNode(true);
    if (letterNode) {
      letterNode.dataset.scrollId = letter;
      letterNode.id = `letter-${letter}`;

      return letterNode;
    } else {
      return null;
    }
  };

  setupLetters = () => {
    for (const [letter, translations] of Object.entries(this.dictionary)) {
      const letterNode = this.setupLetter(letter);
      if (letterNode) {
        translations.forEach((translation, index) => {
          const wordNode = this.setupWord(translation, index);
          if (wordNode) {
            letterNode
              .querySelector(".dictionary__letters__letter__words")
              .appendChild(wordNode);
          }
        });

        this.lettersNode.appendChild(letterNode);
        this.scroll.register(letter, this.updateProgress);
      }
    }
  };

  updateDictionary = (dictionary, key) => {
    for (const [letter, translations] of Object.entries(this.dictionary)) {
      this.scroll.unregister(letter);
    }
    this.dictionary = dictionary;
    this.key = key;
    this.lettersNode.innerHTML = "";
    this.setupLetters();
    this.scroll.update();
    this.scroll.scrollTo(0, {
      disableLerp: true,
      duration: 0,
    });
  };

  updateProgress = (scrollItem, scroll) => {
    if (
      scroll.y >= scrollItem.top - this.marginTop &&
      scroll.y <= scrollItem.bottom
    ) {
      this.letterEnterCallbacks.forEach((callback) => {
        callback(scrollItem.id);
      });
    }
  };

  scrollTo = (index) => {
    const letter = this.letters[index];
    if (letter) {
      this.scroll.scrollTo(`#letter-${letter}`, {
        disableLerp: true,
        duration: 0,
        offset: -this.marginTop,
      });
    }
  };

  layout = () => {
    /*this.lettersNode.style.filter = `blur(${
      8 * this.animations.pickerStateChanged.styles.blur.current
    }px)`;*/
    this.lettersNode.style.opacity = `${
      1 - 0.6 * this.animations.pickerStateChanged.styles.blur.current
    }`;
  };

  render = () => {
    for (const key1 in this.animations) {
      for (const key2 in this.animations[key1].styles) {
        this.animations[key1].styles[key2].current =
          this.animations[key1].styles[key2].setValue();
      }
    }

    this.layout();
  };

  onReady = () => {
    return new Promise((resolve, reject) => {
      this.scroll = getScroll();
      this.marginTop = this.block.getBoundingClientRect().top;
      this.setupLetters();
      this.onResize();

      resolve();
    });
    //this.raf = getRaf();
    //this.raf.register("dictionary", this.render);
  };

  onComplete = () => {};

  onResize = () => {
    this.marginTop = this.lettersNode.getBoundingClientRect().top;
  };
}
