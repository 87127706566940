import "./style.css";
import { getRaf } from "@app";

export default class Letter {
  constructor(block, index) {
    this.block = block;
    this.index = index;
    this.animations = {
      showHide: {
        dampingFactor: 0.09,
        node: block,
        styles: {
          opacity: {
            style: () => this.animations.showHide.styles.opacity.current,
            toValue: 0,
            fromValue: 0,
            current: 0,
            setValue: () => {
              this.animations.showHide.styles.opacity.fromValue =
                this.animations.showHide.styles.opacity.fromValue +
                (this.animations.showHide.styles.opacity.toValue -
                  this.animations.showHide.styles.opacity.fromValue) *
                  this.animations.showHide.dampingFactor;

              return this.animations.showHide.styles.opacity.fromValue;
            },
          },
        },
      },
    };

    this.raf = getRaf();
    this.raf.register(`letter_${this.index}`, this.render);
  }

  setOpacity = (value) => {
    this.animations.showHide.styles.opacity.toValue = value;
  };

  layout = () => {
    for (const key1 in this.animations) {
      for (const key2 in this.animations[key1].styles) {
        this.animations[key1].node.style[key2] =
          this.animations[key1].styles[key2].style();
      }
    }
  };

  render = () => {
    for (const key1 in this.animations) {
      for (const key2 in this.animations[key1].styles) {
        this.animations[key1].styles[key2].current =
          this.animations[key1].styles[key2].setValue();
      }
    }

    this.layout();
  };

  unregisterRaf = () => {
    this.raf.unregister(`letter_${this.index}`);
  };

  onReady = () => {
    return new Promise((resolve, reject) => {
      this.mounted = true;

      resolve();
    });
  };

  onResize = () => {};
}
