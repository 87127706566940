import "./style.css";
import { getRaf } from "@app";
import clamp from "@utils/clamp";

export default class TextMaskBackground {
  static selector = ".text-mask-background";

  constructor(block) {
    this.block = block;

    this.animations = {
      wordBackgroundPosition: {
        node: this.block,
        styles: {
          backgroundPositionY: {
            dampingFactor: 0.09,
            deltaX: 1,
            deltaX0: 0,
            current: 0,
            setValue: () => {
              this.animations.wordBackgroundPosition.styles.backgroundPositionY.deltaX0 =
                this.animations.wordBackgroundPosition.styles
                  .backgroundPositionY.deltaX0 +
                (this.animations.wordBackgroundPosition.styles
                  .backgroundPositionY.deltaX -
                  this.animations.wordBackgroundPosition.styles
                    .backgroundPositionY.deltaX0) *
                  this.animations.wordBackgroundPosition.styles
                    .backgroundPositionY.dampingFactor;

              return this.animations.wordBackgroundPosition.styles
                .backgroundPositionY.deltaX0;
            },
          },
        },
      },
    };
  }

  updateBackgroundPosition = (position) => {
    this.animations.wordBackgroundPosition.node.style.backgroundPositionY = `${
      position * this.blockHeight
    }px, 0`;
  };

  setItemHeight = () => {
    this.blockHeight = this.block.offsetHeight;
  };

  render = () => {
    for (const key1 in this.animations) {
      for (const key2 in this.animations[key1].styles) {
        this.animations[key1].styles[key2].current =
          this.animations[key1].styles[key2].setValue();
      }
    }

    this.layout();
  };

  layout = () => {
    this.animations.wordBackgroundPosition.node.style.backgroundPositionY = `${
      this.animations.wordBackgroundPosition.styles.backgroundPositionY
        .current * this.blockHeight
    }px, 0`;
  };

  onReady = () => {
    return new Promise((resolve, reject) => {
      this.onResize();

      resolve();
    });
  };

  onResize = () => {
    this.setItemHeight();

    /*if (!this.raf) {
      this.raf = getRaf();
      this.raf.register(this.block.dataset.instanceIndex, this.render);
    }*/
  };
}
