import "./style.css";
import layout from "./layout.html";

export default class Nav {
  constructor(block) {
    this.block = block;

    this.block.innerHTML = layout;
  }

  onReady = () => {
    this.mounted = true;
  };

  onResize = () => {};
}
