import "./style.css";
import layout from "./layout.html";
import { getInstance } from "@app";

export default class About {
  static selector = ".about";

  constructor(block) {
    this.block = block;
    this.block.innerHTML = layout;
    this.hidden = true;
    this.animatedTexts = [];
  }

  toggleHidden = (event) => {
    event.preventDefault();
    if (this.hidden) {
      this.show();
    } else {
      this.hide();
    }
    this.hidden = !this.hidden;
  };

  show = () => {
    document.body.classList.add("scale-down");
    this.block.classList.remove("about--hidden");
    this.navAnimatedText.hide();
    setTimeout(() => {
      this.animatedTexts.forEach((instance) => {
        instance.show();
      });
    }, 700);
  };

  hide = () => {
    document.body.classList.remove("scale-down");
    this.block.classList.add("about--hidden");

    this.animatedTexts.forEach((instance) => {
      instance.hide();
    });

    setTimeout(() => {
      this.navAnimatedText.show();
    }, 1200);
  };

  onReady = () => {
    return new Promise((resolve, reject) => {
      this.mounted = true;

      resolve();
    });
  };

  onComplete = () => {
    this.animatedTextNodes = this.block.querySelectorAll(".animated-text");
    this.animatedTextNodes.forEach((node) => {
      const instance = getInstance(node);
      if (instance) {
        this.animatedTexts.push(instance);
      }
    });

    this.toggleAboutNodes = document.querySelectorAll(".toggle-about");
    this.toggleAboutNodes.forEach((node) => {
      node.addEventListener("click", this.toggleHidden);
    });

    const navAnimatedTextNode = document.querySelector("nav .animated-text");
    this.navAnimatedText = getInstance(navAnimatedTextNode);
  };

  onResize = () => {};
}
