import LocomotiveScroll from "locomotive-scroll";

export default class Scroll {
  constructor() {
    this.scrollItems = [];
    this.onCallCallbacks = [];
    this.positionChangeCallbacks = [];
    this.scrollEndCallbacks = [];

    this.scroll = new LocomotiveScroll({
      el: document.querySelector("[data-scroll-container]"),
      smooth: true,
      tablet: { smooth: true },
      smartphone: { smooth: true },
    });

    this.scroll.on("scroll", this.onScroll);
    this.scroll.on("call", this.onCall);
  }

  onCall = (scrollID, way) => {
    this.onCallCallbacks.forEach((item) => {
      if (item.scrollID === scrollID) {
        item.callback(way);
      }
    });
  };

  onScroll = (args) => {
    this.isScrollEnded = false;
    clearTimeout(this.isScrolling);

    this.positionChangeCallbacks.forEach((item) => {
      item.callback(args.scroll);
    });

    this.scrollItems.forEach((item) => {
      if (typeof args.currentElements[item.scrollID] === "object") {
        item.callback(args.currentElements[item.scrollID], args.scroll);
      }
    });

    this.isScrolling = setTimeout(() => {
      this.scrollEnded();
    }, 200);
  };

  scrollEnded = () => {
    this.isScrollEnded = true;
    this.scrollEndCallbacks.forEach((item) => {
      item.callback();
    });
  };

  unregisterFromScrollEnd = (callbackID) => {
    this.scrollEndCallbacks = this.scrollEndCallbacks.filter((callback) => {
      return callback.id !== callbackID;
    });
  };

  registerOnScrollEnd = (id = "", callback) => {
    this.scrollEndCallbacks.push({
      id: id,
      callback: callback,
    });
  };

  unregisterFromScrollPositionChange = (callbackID) => {
    this.positionChangeCallbacks = this.positionChangeCallbacks.filter(
      (callback) => {
        return callback.id !== callbackID;
      }
    );
  };

  registerOnScrollPositionChange = (id = "", callback) => {
    this.positionChangeCallbacks.push({
      id: id,
      callback: callback,
    });
  };

  registerOnCallEvent = (scrollID, callback) => {
    this.onCallCallbacks.push({
      scrollID: scrollID,
      callback: callback,
    });
  };

  register = (scrollID, callback) => {
    this.scrollItems.push({
      scrollID: scrollID,
      callback: callback,
    });
  };

  unregister = (scrollID) => {
    this.scrollItems = this.scrollItems.filter((item) => {
      return item.scrollID !== scrollID;
    });
  };

  update = () => {
    this.scroll.update();
  };

  stop = () => {
    this.scroll.stop();
  };

  start = () => {
    this.scroll.start();
  };

  scrollTo = (target, options = {}) => {
    this.scroll.scrollTo(target, options);
  };
}
