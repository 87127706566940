import "./style.css";
import layout from "./layout.html";

export default class DictionaryPicker {
  constructor(block) {
    this.block = block;

    this.block.innerHTML = layout;
    this.block.addEventListener("click", this.onClick);
    this.onClickCallbacks = [];
    this.animations = {
      pickerStateChanged: {
        node: this.lettersNode,
        styles: {
          blur: {
            dampingFactor: 0.09,
            toValue: 0,
            fromValue: 0,
            current: 0,
            setValue: () => {
              this.animations.pickerStateChanged.styles.blur.fromValue =
                this.animations.pickerStateChanged.styles.blur.fromValue +
                (this.animations.pickerStateChanged.styles.blur.toValue -
                  this.animations.pickerStateChanged.styles.blur.fromValue) *
                  this.animations.pickerStateChanged.styles.blur.dampingFactor;

              return this.animations.pickerStateChanged.styles.blur.fromValue;
            },
          },
        },
      },
    };
  }

  layout = () => {
    this.block.style.filter = `blur(${
      8 * this.animations.pickerStateChanged.styles.blur.current
    }px)`;
    this.block.style.opacity = `${
      1 - 0.6 * this.animations.pickerStateChanged.styles.blur.current
    }`;
  };

  render = () => {
    for (const key1 in this.animations) {
      for (const key2 in this.animations[key1].styles) {
        this.animations[key1].styles[key2].current =
          this.animations[key1].styles[key2].setValue();
      }
    }

    this.layout();
  };

  registerOnClick = (callback) => {
    this.onClickCallbacks.push(callback);
  };

  onClick = () => {
    this.onClickCallbacks.forEach((callback) => {
      callback();
    });
    this.block.classList.toggle("dictionary-picker--left");
    this.block.classList.toggle("dictionary-picker--right");
  };

  onReady = () => {
    return new Promise((resolve, reject) => {
      this.mounted = true;
      this.raf = getRaf();
      this.raf.register("dictionaryPicker", this.render);

      resolve();
    });
  };

  onResize = () => {};
}
