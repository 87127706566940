import "./style.css";
import layout from "./layout.html";
import { getRaf, getInstance, getScroll } from "@app";
import Letter from "@components/Letter";

export default class LetterPicker {
  constructor(block, letters = ["a", "b", "c", "d", "e"]) {
    this.block = block;

    this.block.innerHTML = layout;
    this.container = this.block.querySelector(".letter-picker__container");
    this.lettersNode = this.block.querySelector(".letter-picker__letters");
    this.letterTemplate = this.block.querySelector("#letter-picker__letter");

    this.letters = letters;
    this.index = 0;
    this.opened = false;
    this.textMaskBackgroundInstances = [];
    this.onOpenCallbacks = [];
    this.onCloseCallbacks = [];
    this.onChangeIndexCallbacks = [];
    this.animations = {
      changeIndex: {
        node: this.lettersNode,
        styles: {
          translateY: {
            dampingFactor: 0.09,
            toValue: 0,
            fromValue: 0,
            current: 0,
            setValue: () => {
              this.animations.changeIndex.styles.translateY.fromValue =
                this.animations.changeIndex.styles.translateY.fromValue +
                (this.animations.changeIndex.styles.translateY.toValue -
                  this.animations.changeIndex.styles.translateY.fromValue) *
                  this.animations.changeIndex.styles.translateY.dampingFactor;

              return this.animations.changeIndex.styles.translateY.fromValue;
            },
          },
        },
      },

      openClose: {
        node: this.lettersNode,
        styles: {
          rowGap: {
            dampingFactor: 0.09,
            toValue: 0,
            fromValue: 0,
            current: 0,
            setValue: () => {
              this.animations.openClose.styles.rowGap.fromValue =
                this.animations.openClose.styles.rowGap.fromValue +
                (this.animations.openClose.styles.rowGap.toValue -
                  this.animations.openClose.styles.rowGap.fromValue) *
                  this.animations.openClose.styles.rowGap.dampingFactor;

              return this.animations.openClose.styles.rowGap.fromValue;
            },
          },
        },
      },
    };
  }

  calculateLettersDistance = () => {
    this.lettersDistance =
      this.letterNodes[1].getBoundingClientRect().top - this.letterHeight;
  };

  calculateLetterHeight = () => {
    this.letterHeight = this.letterNodes[0].getBoundingClientRect().height;
  };

  isLetterPickerOpen = () => {
    return this.opened;
  };

  updateLetter = (letter) => {
    const newIndex = this.letters.indexOf(letter);
    this.index = newIndex;
    this.animations.changeIndex.styles.translateY.toValue = newIndex;
  };

  updateIndex = (index) => {
    this.index = index;
    this.animations.changeIndex.styles.translateY.toValue = index;
    this.onChangeIndexCallbacks.forEach((callback) => {
      callback(index);
    });
  };

  open = () => {
    if (this.scroll.isScrollEnded) {
      this.scroll.registerOnScrollPositionChange("letter-picker", this.close);
    } else {
      this.scroll.registerOnScrollEnd("letter-picker", () => {
        this.scroll.registerOnScrollPositionChange("letter-picker", this.close);
        this.scroll.unregisterFromScrollEnd("letter-picker");
      });
    }
    this.animations.openClose.styles.rowGap.toValue = 1;
    this.opened = true;
    this.onOpenCallbacks.forEach((callback) => {
      callback();
    });
  };

  close = () => {
    this.scroll.unregisterFromScrollPositionChange("letter-picker");
    this.animations.openClose.styles.rowGap.toValue = 0;
    this.opened = false;
    this.onCloseCallbacks.forEach((callback) => {
      callback();
    });
  };

  onLetterNodeClicked = (event) => {
    const target = event.currentTarget;
    const newIndex = parseInt(target.dataset.index, 10);
    if (this.isLetterPickerOpen()) {
      this.updateIndex(newIndex);
      this.close();
    } else {
      this.open();
    }
  };

  layout = () => {
    const openProgress = this.animations.openClose.styles.rowGap.current;
    const translateProgress =
      this.animations.changeIndex.styles.translateY.current;

    let rowGap = 36 - 24 * openProgress;
    this.lettersNode.style.rowGap = `${rowGap}px`;

    const letterHeight = this.letterHeight + rowGap;
    const lettersHeight = letterHeight * this.letters.length;

    const translateY =
      (this.windowSizes.height / 2 - lettersHeight / 2 - this.offsetTop) *
        openProgress -
      letterHeight * translateProgress * (1 - openProgress);

    this.container.style.transform = `translate3d(0, ${translateY}px, 0)`;

    this.textMaskBackgroundInstances.forEach((instance, index) => {
      const position =
        index !== this.index
          ? this.index - index
          : this.index - index - (1 - openProgress);
      instance.updateBackgroundPosition(position);
    });

    this.letterInstances.forEach((letter, index) => {
      letter.setOpacity(this.opened ? 1 : index === this.index ? 1 : 0);
    });
  };

  render = () => {
    for (const key1 in this.animations) {
      for (const key2 in this.animations[key1].styles) {
        this.animations[key1].styles[key2].current =
          this.animations[key1].styles[key2].setValue();
      }
    }

    this.layout();
  };

  setupLetter = (letter, index) => {
    let letterNode =
      this.letterTemplate.content.firstElementChild.cloneNode(true);

    if (letterNode) {
      const textMaskBackgroundNode = letterNode.querySelector(
        ".text-mask-background"
      );
      textMaskBackgroundNode.textContent = `${letter.toUpperCase()}${letter}`;
      letterNode.dataset.index = index;

      return letterNode;
    } else {
      return null;
    }
  };

  setupLetters = () => {
    this.letters.forEach((letter, index) => {
      const letterNode = this.setupLetter(letter, index);

      if (letterNode) {
        this.lettersNode.appendChild(letterNode);
      }
    });

    window.App.initClones();
    this.letterNodes = this.block.querySelectorAll(
      ".letter-picker__letters__letter"
    );
    this.letterInstances = [];
    this.letterNodes.forEach((node, index) => {
      this.letterInstances.push(new Letter(node, index));
      node.addEventListener("click", this.onLetterNodeClicked);
    });

    const textMaskBackgroundNodes = this.block.querySelectorAll(
      ".text-mask-background"
    );
    this.textMaskBackgroundInstances = [];
    textMaskBackgroundNodes.forEach((node) => {
      const instance = getInstance(node);

      instance && this.textMaskBackgroundInstances.push(instance);
    });
  };

  updateLetters = (letters) => {
    this.raf.unregister(this.block.dataset.instanceIndex);
    this.letterInstances.forEach((letter) => {
      letter.unregisterRaf();
    });
    this.animations.changeIndex.styles.translateY.toValue = 0;
    this.letters = letters;
    this.lettersNode.innerHTML = "";
    this.setupLetters();
    this.raf.register(this.block.dataset.instanceIndex, this.render);
  };

  registerOnOpen = (callback) => {
    this.onOpenCallbacks.push(callback);
  };

  registerOnClose = (callback) => {
    this.onCloseCallbacks.push(callback);
  };

  registerOnChangeIndex = (callback) => {
    this.onChangeIndexCallbacks.push(callback);
  };

  onReady = () => {
    return new Promise((resolve, reject) => {
      this.mounted = true;
      this.setupLetters();
      this.onResize();
      this.raf = getRaf();
      this.raf.register(this.block.dataset.instanceIndex, this.render);
      this.scroll = getScroll();

      resolve();
    });
  };

  onResize = () => {
    this.calculateLetterHeight();
    this.calculateLettersDistance();
    this.offsetTop = this.block.offsetTop;
    this.windowSizes = {
      width: window.innerWidth,
      height: window.innerHeight,
    };
    this.layout();
  };
}
