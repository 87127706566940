import it from "./it_na.json";
import na from "./na_it.json";
let it_na = {};
for (let i = 0; i < it.length; i += 2) {
  it_na[it[i]] = it[i + 1].slice(0, 25);
}

let na_it = {};
for (let i = 0; i < na.length; i += 2) {
  na_it[na[i]] = na[i + 1].slice(0, 25);
}

const dictionaries = {
  it_na: it_na,
  na_it: na_it,
};

export default dictionaries;
