import "./style.css";
import { getObserver, getRaf } from "@app";
import { easeOutQuint } from "@utils/easing";
import clamp from "@utils/clamp";

export default class AnimatedText {
  static selector = ".animated-text";

  constructor(block) {
    this.block = block;

    this.splitted = this.block.dataset.splitted;
    this.transitionDuration = 1600;
    this.entranceDelegated = block.dataset.entranceDelegated;
    this.backgroundImage = block.dataset.backgroundImage;
    this.outTop = block.dataset.outTop;
    this.hiding = false;
    this.animations = {};
    this.rowDelay = this.block.dataset.rowDelay
      ? parseInt(this.block.dataset.rowDelay)
      : 0;
  }

  wordsCount = (str) => {
    str = str.replace(/(^\s*)|(\s*$)/gi, "");
    str = str.replace(/[ ]{2,}/gi, " ");
    str = str.replace(/\n /, "\n");
    return str.split(" ").length;
  };

  addBckgroundImageToRows = () => {
    this.textRows.forEach((row) => {
      row.style.backgroundImage = `url(${this.backgroundImage})`;
    });
  };

  getRow = (text) => {
    this.block.textContent = text;

    while (this.block.scrollWidth > this.block.offsetWidth) {
      let lastIndex =
        this.wordsCount(text) > 1 ? text.lastIndexOf(" ") : text.length - 1;
      text = text.substring(0, lastIndex);
      this.block.textContent = text;
    }

    const result = this.block.textContent;
    return result;
  };

  setupRows = () => {
    if (this.splitted === "true") {
      this.textRows = this.block.querySelectorAll(".animated-text__row");
      if (this.backgroundImage) {
        this.addBckgroundImageToRows();
      }
      return;
    }

    this.block.style.overflow = "hidden";
    let text = this.block.textContent;
    let textRows = [];

    while (text.length > 0) {
      let newRow = this.getRow(text);
      textRows.push(newRow);
      text = text.substring(newRow.length, text.length);
    }

    this.block.innerHTML = "";
    textRows.forEach((row, i) => {
      let rowWrapper = document.createElement("span");
      rowWrapper.classList.add("animated-text__wrapper");

      let span = document.createElement("span");
      span.classList.add("animated-text__row");

      span.textContent = row;
      rowWrapper.appendChild(span);
      this.block.appendChild(rowWrapper);
    });

    this.textRows = this.block.querySelectorAll(".animated-text__row");
    if (this.backgroundImage) {
      this.addBckgroundImageToRows();
    }
    this.block.style.overflow = null;
  };

  resetInOutAnimation = (out) => {
    for (const key1 in this.animations) {
      for (const key2 in this.animations[key1].styles) {
        this.raf.unregister(key1);

        this.animations[key1].out = out;
        this.animations[key1].startTimestamp = null;
        this.animations[key1].styles[key2].current = !out
          ? 0
          : this.animations[key1].styles[key2].current;
        this.animations[key1].styles[key2].toValue = !out ? 1 : 2;
        this.animations[key1].styles[key2].fromValue =
          this.animations[key1].styles[key2].current;

        setTimeout(() => {
          this.raf.register(key1, (timestamp) => {
            this.render(timestamp, key1);
          });
        }, this.animations[key1].delay());
      }
    }
  };

  show = () => {
    this.resetInOutAnimation(false);
  };

  hide = () => {
    this.resetInOutAnimation(true);
  };

  setupAnimations = () => {
    this.textRows.forEach((row, index) => {
      this.animations[`row_${index}`] = {
        delay: () => {
          const animation = this.animations[`row_${index}`];
          return animation.out ? 0 : this.rowDelay * index;
        },
        duration: 1000,
        easing: easeOutQuint,
        out: true,
        node: row,
        styles: {
          transform: {
            style: () => {
              const value =
                this.animations[`row_${index}`].styles.transform.current;
              return `translate3d(0, ${110 - 110 * value}%, 0)`;
            },
            toValue: 1,
            fromValue: 0,
            current: 0,
            setValue: (progress) => {
              const animation = this.animations[`row_${index}`];
              const value =
                animation.styles.transform.fromValue +
                (animation.styles.transform.toValue -
                  animation.styles.transform.fromValue) *
                  progress;

              return value;
            },
          },
        },
      };
    });
  };

  layout = () => {
    for (const key1 in this.animations) {
      for (const key2 in this.animations[key1].styles) {
        this.animations[key1].node.style[key2] =
          this.animations[key1].styles[key2].style();
      }
    }
  };

  render = (timestamp, key) => {
    for (const key2 in this.animations[key].styles) {
      if (!this.animations[key].startTimestamp) {
        this.animations[key].startTimestamp = timestamp;
      }

      const progress = this.animations[key].easing(
        clamp(
          (timestamp - this.animations[key].startTimestamp) /
            this.animations[key].duration,
          0,
          1
        )
      );

      this.animations[key].styles[key2].current =
        this.animations[key].styles[key2].setValue(progress);
    }

    this.layout();
  };

  onReady = () => {
    return new Promise((resolve, reject) => {
      this.mounted = true;
      this.vw = window.innerWidth;
      this.setupRows();
      this.setupAnimations();
      this.raf = getRaf();

      resolve();
    });
  };

  onComplete = () => {
    if (!this.entranceDelegated) {
      this.block.dataset.intersectionRatio = 0.8;
      const observer = getObserver();
      observer.register(
        this.block.dataset.instanceIndex,
        this.show,
        this.block
      );
    }
  };

  onPageChangeComplete = () => {
    this.onComplete();
  };

  onResize = () => {
    if (this.vw && window.innerWidth === this.vw) {
      return;
    }

    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    this.timeout = setTimeout(() => {
      this.setupRows();
    }, 400);
  };
}
